@import '../../sass/colors';

:local(.unmatched) {
  span:nth-child(2) {
    span {
      color: $company-structure-unmatched-node-color;
    }
  }
}

:local(.matched) {
  span:nth-child(2) {
    span {
      font-weight: bold;
      color: $company-structure-matched-node-color;
    }
  }
}
